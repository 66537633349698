<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="id && getAccess(`${config.accessName}.site`) ? '100hv' : '800px'">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.city + " " + data.address : `Новый '${config.title}'` }}
      </template>
      <v-row v-if="id && getAccess(`${config.accessName}.site`)">
        <v-col cols="12" sm="6">
          <v-card-title class="pt-0"> Информация об объекте </v-card-title>
          <v-row class="mt-1" :style="{ height: $root.windowSize.innerHeight - 150 - 50 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
            <v-col cols="12" class="py-0 pt-1">
              <a-form-model
                :ref="'field_status_site'"
                v-model="data"
                :model="getFormModel(['status_site'], model)"
                :errors="errors"
                :config="{ dense: true, readonly: !getAccess('site.status') }"
                @validate="onChangeStatus($event)"
              />
            </v-col>
            <portal-target :name="`form-agent-object-` + id"></portal-target>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" :style="{ height: $root.windowSize.innerHeight - 150 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
          <s-props-form
            v-if="value"
            :model="{ title: 'Дополнительные данные для сайта', id, type: config.siteName }"
            :id="id"
            :type="config.siteName"
            :config="propsConfig"
            :objectData="data"
          />
        </v-col>
      </v-row>
      <v-layout v-else px-2 py-2 mt-1 row wrap>
        <portal-target :name="`form-agent-object-` + id"></portal-target>
      </v-layout>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="canSave" @click="submit()" class="pr-2"></a-btn-save>
        <a-btn-delete v-if="id && getAccess(`${config.accessName}.delete`)" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <portal :to="`form-agent-object-` + id">
      <a-form-modelCols
        v-model="data"
        class="pb-3 mx-1 pt-2"
        :model="getFormModel(fieldsF, model)"
        :values="data"
        :errors="errors"
        :config="{ dense: true, readonly, hideDetails: 'auto' }"
        @validate="
          validate($event);
          doWatch($event);
        "
      />
    </portal>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";

export default {
  mixins: [getAccess, getForm, submitForm, removeEl, genModel],
  props: {
    value: Boolean,
    typeObject: { type: String, default: "lampObjects" },
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects,
      model: [],
      removeDialogShow: false,
      suEditShow: false,
      panelPlan: null,
      fieldsF: "",
    };
  },
  computed: {
    config() {
      //console.log("config", this.m?.config);
      const res = this.m?.config?.[this.typeObject] || {};
      //console.log("config2", this.typeObject, res);
      return res;
    },
    model1() {
      let model = this.calcModelConfig(this.config);
      ///console.log(this.typeObject, this.config.fieldsForm, model);
      return model;
    },
    propsConfig() {
      let res = {};
      res.readonly = !this.getAccess("site.edit");
      return res;
    },
    name() {
      if (!this.data) return "";
      return this.data.city + " " + this.data.address;
    },
    vendor: {
      get() {
        let res = this.data.data?.vendor || "";
        return res;
      },
      set(v) {
        if (!this.data.data?.vendor) this.data.data = Object.assign({}, this.data.data, { vendor: "" });

        this.data.data.vendor = v;
      },
    },
    readonly() {
      if (!this.id) return false;
      return !this.getAccess(this.config.accessName + ".edit", {
        id: this.id,
        data: this.data,
      });
    },
    canSave() {
      return this.getAccess(`${this.config.accessName}.edit`) || (this.getAccess(`${this.config.accessName}.create`) && !this.id);
    },
  },
  watch: {
    "data.ap_price_buy"() {
      this.calcPrice_m2();
    },
    "data.ap_area"() {
      this.calcPrice_m2();
    },

    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },

  created() {
    if (!true) {
      this.m.config.agentObjects = {
        accessName: "agentObjects",
        siteName: "agentObjects",
        viewName: "objectsAgent_view",
        api: "/mechti/agent_objects",
        title: "Агентский объект",
        watch: "residential_type",
        fields:
          "link_ref,status,source,name,plan_sell_price,plan_expenses,credit_first_payment,plan_org_expenses,data.doc_buy_price,data.doc_sell_price,plan_month_expenses,data.donor_id,plan_investment_amount,data.is_procuratory,data.is_agreement_investor,data.is_agreement_donor,plan_date_finish,city,address,residential_complex_id,class,type,building_type,ap_area_real,ap_area_plan,layout_legal,ap_area,ap_kitchen_area,price_m2,,ap_floor,building_floors,ap_number,ap_rooms,ap_ceiling_height,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law,photos_design,residential_complex_id,residential_type,ap_price_adv,data.cadastralNumber,data.passengerElevator,data.freightElevator,data.balconies,data.loggias,data.wardrobe,data.windows,data.separateWcsCount,data.combinedWcsCount,data.parking,",
        fieldsRO: "",
        viewForm2: { fields: "link_ref,name,city,adress,residential_complex_id#8,residential_type#4,ap_rooms,ap_area,ap_price_buy,price_m2,building_floors,ap_floor" },
        fieldsForm:
          "name,source,link_ref,city#4,address#8,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,r,data.cadastralNumber#12,data.passengerElevator#3,data.freightElevator#3,data.balconies#3,data.loggias#3,data.wardrobe#3,data.windows#3,data.separateWcsCount#3,data.combinedWcsCount#3,data.parking,hr,ap_area#3,ap_kitchen_area#3,ap_price_adv#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law",
        fieldsReplace: {
          status_site: {
            name: "status_site",
            title: "Публиковать на",
            type: "selectbit",
            options: [
              { value: 1, text: "1", default: true, show: false },
              { value: 2, text: "Cайт" },
              { value: 3, text: "Авито" },
              { value: 4, text: "Циан" },
            ],
          },
          status: { name: "status", title: "Статус", type: "select", options: "status_site", sourceList: "config.statuses.lamp_object", default: 1 },
        },
        1: {
          fields: `link_ref,status,source,name,city,address,class,type,building_type,data.landArea,ap_area,building_floors,
          data.settlementName,data.kp,data.hasBathhouse,data.hasPool,
          data.houseAdditionally,data.buildingCadastralNumber,data.gasSupply,data.heatingType,data.waterSupply,
          data.parkingType,data.sewerage,data.builtYear,data.wallsType,
          ap_rooms,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law,photos_design,residential_complex_id,residential_type,ap_price_adv,data.cadastralNumber,data.passengerElevator,data.freightElevator,data.balconies,data.loggias,data.wardrobe,data.windows,data.separateWcsCount,data.combinedWcsCount,data.parking`,
          fieldsRO: "",
          fieldsForm: `name,source,link_ref,city#4,address#8,class#4,type#4,residential_type#4,
       hr,
        data.settlementName#8,data.kp#4,
        data.landArea#3,ap_area#3,building_floors#3,ap_rooms#3,
        data.houseAdditionally#3,data.gasSupply#3,data.heatingType#3,data.waterSupply#3,
        data.sewerage#3,data.parkingType#3,data.builtYear#3,data.wallsType#3,
        data.hasBathhouse#3,data.hasPool#3,ap_price_adv#6,
        data.cadastralNumber#6,data.buildingCadastralNumber#6,
        hr ,
        photos,photos_design,doc,doc_adv,doc_law`,
          fieldsReplace: { ap_area: { name: "ap_area", title: "Площадь дома", type: "number", validator: ["req", "min#1"] } },
        },
      };
      let f = this.m.form;
      f.push(...[]);
      this.m.form = [...f];
    }
  },
  methods: {
    doWatch(e) {
      // console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      console.log("do watch");
      if (e == this.m?.config.agentObjects?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },
    getModel(t) {
      let config = JSON.parse(JSON.stringify(this.config));
      if (t || t === 0) config = JSON.parse(JSON.stringify(this.m?.config.agentObjects?.[t] || this.config));
      //if (this.data.status == 2) config.fieldsRO = config.fields;
      this.watchModel = config?.watch || null;

      let model = this.calcModelConfig(config);
      if (true)
        model.forEach((el) => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fieldsF = config.fieldsForm.replace(" ", "").replace(/\s/g, "").split(",");
      //model = this.getFormModel(config.fieldsForm.replace(" ", "").replace(/\s/g, "").split(","), model);
      this.model = model;
      this.fillFormFromModel(model);
      this.inputData(this.initData);
      //   console.log("model", model);
    },
    calcPrice_m2() {
      this.data.price_m2 = "";
      if (this.data.ap_area && this.data.ap_price_buy) {
        this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
      }
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: this.typeObject });
    },
    async afterSave(data, status) {
      if (status) this.$emit("input");
    },
    afterFetchData(r) {
      let t = 0;
      if (this.m?.config.agentObjects?.watch) {
        t = this.data?.[this.m.config.agentObjects.watch] || 0;
      }
      console.log("aft", t);
      this.getModel(t);
      this.loaded = true;
    },
    inputData(d = {}) {
      if (this.id) return;
      if (d)
        for (let i = 0; i < this.model.length; i++) {
          let el = this.model[i];
          if (el?.hidden == true) {
          } else {
            if (!el.name.includes("data.")) {
              this.$set(this.data, el.name, d?.[el.name]);
            }
          }
        }
    },
  },
};
</script>
